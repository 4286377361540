/* global $, jQuery, AOS */
import * as dompack from 'dompack';
import { WWBasicSite, WWForms } from '@mod-ww/webdesigns/template/ww';
// import { WWForms } from '@mod-ww/webdesigns/template/ww';

import './ayce.scss';

dompack.onDomReady(() => {
  setTimeout(() => {
    document.documentElement.classList.add('init');
  },100);

  new WWBasicSite({});
  new WWForms();
  setupAOS();

  $('main > iframe').addClass('ww-grid').css('height', 500);

  $('.w-videoslider__item').each(function() {
    const $videoSlider = $(this).parent('.w-videoslider');
    const videoId = $videoSlider.get(0).dataset.videoid;
    const coverImageLink = $videoSlider.data('posterimage');
    $(this).html('').append(`<div id="bgndVideo" class="player" data-property="{coverImage: '${coverImageLink}',stopMovieOnBlur:false, showControls:false, showYTLogo:false, videoURL:'https://youtu.be/${videoId}',containment:'self',useOnMobile:false,autoPlay:true, mute:true, startAt:0, opacity:1}"></div>`);
    $(this).closest('.widget').addClass('no-animation');
    $('#bgndVideo').css('background', '#000');
  });

  document.documentElement.classList.add('start-header-animation');

  if (jQuery("#bgndVideo").length) {
    console.log(jQuery("#bgndVideo").YTPlayer());
  }
});

function setupAOS() {
  if (typeof(AOS) == 'undefined') {
    console.error("No AOS found");
    return;
  }

  for (const item of dompack.qSA('main > .widget:not(:first-child)')) {
    item.dataset.aos = 'fade-up';
  }

  AOS.init({ //disable: "mobile",
             offset: document.documentElement.clientHeight > 800 ? 50 : 0,
             duration: 700,
             delay: 100,
           });
}
